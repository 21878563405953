<template>
  <!-- <div class="wameed-filter-modal  "> -->
  <b-modal
    id="warnings-wameed-modal"
    class="warnings-modal"
    v-model="show"
    title=""
    no-fade
    hide-footer
    hide-header
    scrollable
  >
    <div class="align-items-center w-100">
      <b-button
        class="justify-end close px-2"
        variant="light"
        size="sm"
        @click="show = false"
      >
        <close-icon />
      </b-button>
      <div
        class="
          d-flex
          flex-column
          justify-content-between
          align-items-center
          w-100
        "
      >
        <b-avatar variant="light-danger" rounded size="70" class="my-3">
          <logout2-icon />
        </b-avatar>

        <h4 class="text-medium-20 py-2">{{$t('logout')}}</h4>
        <h4 class="text-book-18 py-2 text-center">
         {{ $t('logout_confirmation') }}
         
        </h4>
        <div class="d-flex w-100 justify-content-between">
         
          <wameed-btn
            @submitAction="submitAction()"
            variant="danger"
            size="sm"
            :title="$t('logout')"
            classes="text-book-18 py-5 mx-2 rounded-12 px-4 mt-5 text-white w-50"
          />
          <wameed-btn
            @submitAction="show = false"
            variant="disable"
            size="sm"
            :title="$t('cancel')"
            classes="text-book-18 py-5 mx-2 rounded-12 px-4 w-50 mt-5 text-font-secondary"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import WameedBtn from './WameedBtn.vue';
export default {
  components: { WameedBtn },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    submitAction(){
          this.$emit("submitAction");

    }
  },
};
</script>
 
