<template>
  <b-button  v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="submitAction"  :type="type" :variant="variant" :block="block" :disabled="disabled" :size="size"  class="wameed-btn " :class="classes">
        <component v-if="appendIcon" v-bind:is="appendIcon"></component>
    {{ title }}
      <component v-if="prependIcon" v-bind:is="prependIcon"></component>
  </b-button>
</template>
<script> 
import Ripple from 'vue-ripple-directive'
export default {
  props: {
    classes:{
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
     disabled: {
      type: Boolean,
      default: null,
    },
    variant:{
          type: String,
      default: null,
    },
     size:{
          type: String,
      default: null,
    },
    type:{
         type: String,
      default: 'button',
    },
    appendIcon:{
         type: String,
      default: null,
    },
    prependIcon:{
         type: String,
      default: null,
    },
    block:{
      type: Boolean,
      default: false,
    }
  }, 
  directives: {
    Ripple,
  },
  methods: {
    submitAction() {
      this.$emit("submitAction");
    },
  },
};
</script>
