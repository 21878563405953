export default [
  {
    title: 'dashboard',
    icon: 'home-icon', 
    tagVariant: 'light-warning',
    route: 'dashboard',
  },
  {
    title: 'applicants',
    icon: 'bag-icon', 
    tagVariant: 'light-warning',
    route: 'applicants',
  },
  {
    title: 'training',
    icon: 'trainging-icon', 
    tagVariant: 'light-warning',
    route: 'training-applicants',
  },
  {
    title: 'works_requests',
    icon: 'applicants-icon', 
    tagVariant: 'light-warning',
    route: 'works',
    
  },
  {
    title: 'chat',
    icon: 'chat-icon', 
    tagVariant: 'light-warning',
    route: 'chat',
  },
  {
    title: 'mails',
    icon: 'emails-icon', 
    tagVariant: 'light-warning',
    route: 'mails',
  },
  {
    title: 'settings',
    icon: 'setting-icon', 
    tagVariant: 'light-warning',
    route: 'settings',
  }
]
